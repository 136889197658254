import { globals } from "@/core/globals";

export const getFileExtension = (filename: string | undefined) => {
  if (!filename) {
    return "";
  }
  return filename.split(".").pop()?.split("?")[0] ?? "";
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const getRelativeUrl = (url: string) => {
  try {
    const urlObject = new URL(url);
    return urlObject.pathname + urlObject.search;
  } catch (err) {
    return url;
  }
};

export const getUrl = (url: string) => {
  if (!url) {
    return "#";
  }

  const { optimizelyHost } = globals;
  if (optimizelyHost == null || optimizelyHost === "") {
    return url;
  }

  if (url.startsWith(optimizelyHost)) {
    return getRelativeUrl(url);
  }

  return url;
};

export const getUrlHash = () => {
  let hash = window.location.hash;

  // Check if the hash is not empty
  if (hash) {
    // Remove the '#' character
    hash = hash.substring(1);

    // Decode any percent-encoded characters
    return decodeURIComponent(hash);
  }

  return undefined;
};

export const getBrightness = (hexColor: string) => {
  const rgb = parseInt(hexColor.slice(1), 16); // Convert hex to RGB
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  // Calculate brightness (YIQ formula)
  return (r * 299 + g * 587 + b * 114) / 1000;
};
