<template>
  <div v-if="pages > 1" class="vc-pagination flex flex-col">
    <div class="vc-pagination__result w-full text-center text-sm text-[--color-neutral-a2] md:text-right">
      Result: {{ page }} of {{ pages }}
    </div>
    <div class="vc-pagination__container">
      <div class="vc-pagination__pages">
        <component
          :is="item > 0 && page !== item ? 'button' : 'span'"
          v-for="(item, index) in visiblePages"
          :key="index"
          :type="item > 0 && page !== item ? 'button' : null"
          :class="[
            'vc-pagination__page text-base',
            {
              'vc-pagination__page--active': item && page === item,
              'vc-pagination__page--ellipsis': item === 0,
            },
          ]"
          @click="setPage(item)"
        >
          {{ item || "..." }}
        </component>
      </div>

      <div class="vc-pagination__nav">
        <VcButton
          :aria-label="$t('common.buttons.previous_page')"
          class="vc-pagination__button vc-pagination__button--prev"
          color="secondary"
          variant="outline"
          size="sm"
          :disabled="page === 1"
          @click="setPage(page - 1)"
        >
          <VcIcon
            name="chevron-left"
            class="shrink-0 stroke-[white] stroke-2 text-[--color-footer-top-text]"
            size="xs"
          />
        </VcButton>

        <VcButton
          :aria-label="$t('common.buttons.next_page')"
          class="vc-pagination__button vc-pagination__button--next"
          color="secondary"
          variant="outline"
          size="sm"
          :disabled="page === pages"
          @click="setPage(page + 1)"
        >
          <VcIcon name="chevron-right" class="shrink-0 stroke-[white] stroke-2" size="xs" />
        </VcButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface IEmits {
  (event: "update:page", page: number): void;
}

interface IProps {
  page?: number;
  pages?: number;
  scrollTarget?: HTMLElement;
  scrollOffset?: number;
}

const emit = defineEmits<IEmits>();

const props = withDefaults(defineProps<IProps>(), {
  page: 1,
  pages: 0,
  scrollOffset: 20,
});

const visiblePages = computed(() => {
  const { pages, page } = props;

  // If there are 5 or fewer pages, show all pages without ellipses
  if (pages <= 5) {
    return Array.from({ length: pages }, (_, i) => i + 1);
  }

  const visible = [];

  // Always include the first page
  visible.push(1);

  // Add ellipsis after the first page if the current page is beyond 3
  if (page > 3) {
    visible.push(0); // Ellipsis
  }

  // Show two pages around the current page, keeping only 2 pages between ellipses
  const startPage = Math.max(2, page - 1);
  const endPage = Math.min(pages - 1, page + 1);

  // Add two pages between ellipses
  for (let i = startPage; i <= endPage; i++) {
    if (i > 1 && i < pages) {
      visible.push(i);
    }
  }

  // Add ellipsis before the last page if the current page is far from the end
  if (page < pages - 2) {
    visible.push(0); // Ellipsis
  }

  // Always include the last page
  visible.push(pages);

  return visible;
});

function scrollToTop() {
  if (props.scrollTarget) {
    const topPosition = props.scrollTarget.getBoundingClientRect().top + window.scrollY - props.scrollOffset;
    window.scrollTo({ top: topPosition, behavior: "smooth" });
  }
}

const setPage = (page: number) => {
  if (page) {
    emit("update:page", page);
    scrollToTop();
  }
};
</script>

<style lang="scss">
.vc-pagination {
  @apply flex mx-auto flex-col items-center lg:mx-0 lg:flex-row gap-3 z-0;

  &__container {
    @apply flex lg:border lg:border-[--color-secondary-200] lg:p-3.5 rounded-[10px];
  }

  &__pages {
    @apply flex flex-wrap justify-center;
  }

  &__page {
    @apply flex items-center justify-center min-w-[35px] h-8 md:min-w-[40px] md:h-9 p-1 rounded-[6px] text-xs font-medium;

    &[type="button"] {
      @apply border border-[--color-neutral-a4] rounded-none ml-[-1px];
    }

    &:hover {
      @apply text-[--color-primary-a1] border-[--color-primary-a1] relative z-10;
    }

    &--active {
      @apply border border-[--color-primary-a1] text-[--color-primary-a1] pointer-events-none rounded-none relative z-10;
    }

    &--ellipsis {
      @apply text-[--color-neutral-a1] pointer-events-none;
    }
  }

  &__nav {
    @apply gap-8 justify-between mt-1.5 contents;
  }

  &__button {
    @apply min-w-[36px] #{!important};

    &--prev {
      @apply -order-1 rounded-tr-none rounded-br-none  border-[--color-neutral-a4] #{!important};
    }

    &--next {
      @apply rounded-tl-none rounded-bl-none border-l-transparent border-[--color-neutral-a4] #{!important};
    }
    &:not(.vc-button--disabled) {
      &:hover {
        @apply border-[--color-primary-a1] relative z-10 #{!important};
        svg {
          @apply text-[--color-primary-a1];
        }
      }
    }
  }
}
</style>
