<template>
  <div class="flex flex-col">
    <VcAlert
      v-if="showItemAddedNotification"
      color="success"
      size="lg"
      variant="outline-dark"
      class="mb-6 gap-2"
      icon
      closable
      @close="closeItemAddedAlert"
    >
      {{ itemAddedNotification }}
    </VcAlert>

    <VcAlert
      v-if="showItemRemovedNotification"
      color="success"
      size="lg"
      variant="outline-dark"
      class="mb-6 gap-2"
      icon
      closable
      @close="closeItemRemovedAlert"
    >
      {{ itemRemovedNotification }}
    </VcAlert>

    <div class="header__top flex-auto">
      <!-- Headers -->
      <div v-if="!isMobile" class="mb-5 flex border-b pb-3">
        <span class="flex flex-[0_0_52%] text-base font-semibold">
          {{ $t("common.labels.minicart_item") }}
        </span>
        <span class="header__top-qty flex flex-[0_0_22%] justify-center text-base font-semibold">
          {{ $t("common.labels.minicart_quantity") }}
        </span>
        <span class="flex flex-[0_0_26%] justify-end text-base font-semibold">
          {{ $t("shared.cart.add_bulk_items_to_cart_results_modal.labels.amount") }}
        </span>
      </div>
      <div
        v-for="item in availabilityItems"
        :key="item.lineItem.id"
        class="cart__item mb-5 flex flex-auto flex-col border-b pb-5 md:pb-5"
      >
        <div :class="{ 'flex flex-auto gap-4': true, disabled: isDisabled }" :style="{ opacity: isDisabled ? 0.5 : 1 }">
          <div class="cart__item-image flex size-[80px] items-center md:size-[100px]">
            <VcImage
              :src="item.lineItem.imageUrl"
              alt=""
              multiple-dpr
              :default-size="DEFAULT_DETAIL_THUMB_SIZE"
              :width="DEFAULT_THUMB_SIZE"
              :height="DEFAULT_THUMB_SIZE"
            ></VcImage>
          </div>
          <div class="flex w-full flex-col md:flex-row md:gap-8">
            <div class="cart__item-details flex flex-1 flex-col">
              <a
                class="line-clamp-2 items-start gap-1.5 overflow-hidden text-ellipsis font-semibold text-[--color-link] hover:text-[--color-neutral-a1]"
                :href="getProductFamillyPageUrl(item.lineItem)"
              >
                {{ item.productTitle }}
              </a>
              <p
                v-dompurify-html="getDescription(item.lineItem)"
                class="line-clamp-2 overflow-hidden text-ellipsis text-base font-semibold leading-6"
              ></p>
              <div v-if="isMobile" class="mb-0 mt-3 flex items-start justify-between md:my-0 md:mb-1">
                <div v-if="item.lineItem.listPrice && isMobile" class="flex">
                  <span class="text-base font-normal md:text-sm">
                    {{ $t("shared.catalog.product_details.product_variations.availability") }}: {{ item.status }}
                  </span>
                </div>
                <div v-if="isMobile" class="cart__item-price flex flex-col items-end gap-1 md:gap-0">
                  <span
                    :class="{
                      'price text-base font-bold': true, // Base styles
                      'price-line-trough font-normal text-[--color-neutral-a3] line-through':
                        item.lineItem.placedPrice.amount > 0 &&
                        item.lineItem.listPrice?.amount > item.lineItem.placedPrice.amount, // Apply line-through if there's a discount
                    }"
                  >
                    <VcItemPrice
                      :value="{
                        list: item.lineItem.listPriceAdaptedToQuantityTL,
                        actual: item.lineItem.listPriceAdaptedToQuantityTL,
                      }"
                      :list-with-tax="item.lineItem.listPriceWithTaxAdaptedToQuantityTL"
                      :actual-with-tax="item.lineItem.listPriceWithTaxAdaptedToQuantityTL"
                    />
                  </span>
                  <div
                    v-if="
                      item.lineItem.placedPrice.amount > 0 &&
                      item.lineItem.listPrice?.amount > item.lineItem.placedPrice.amount
                    "
                  >
                    <span class="price-promo text-base font-bold">
                      <VcItemPrice
                        :value="{
                          list: item.lineItem.listPriceAdaptedToQuantityTL,
                          actual: item.lineItem.extendedPrice,
                        }"
                        :list-with-tax="item.lineItem.listPriceWithTaxAdaptedToQuantityTL"
                        :actual-with-tax="item.lineItem.extendedPriceWithTax"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="item.lineItem.listPrice" class="flex items-center justify-between md:justify-normal">
                <!-- <div class="flex flex-col gap-1 md:gap-0">
                  <div
                    v-if="
                      item.lineItem.discounts?.find((d) => d.description === 'VOLUME_CUSTOM') &&
                      item.lineItem.placedPrice.amount > 0 &&
                      item.lineItem.listPrice?.amount > item.lineItem.placedPrice.amount
                    "
                  >
                    <span class="price-per-unit flex gap-1 text-base text-[--color-neutral-a1] md:text-sm">
                      <VcItemPrice
                        :value="{ list: item.lineItem.listPrice, actual: item.lineItem.placedPrice }"
                        :list-with-tax="item.lineItem.listPriceWithTax"
                        :actual-with-tax="item.lineItem.placedPriceWithTax"
                      />
                      {{ $t("shared.cart.per_unit") }}
                    </span>
                  </div>

                  <span
                    :class="{
                      'price-per-unit flex gap-1': true, // Base styles
                      'text-base text-[--color-neutral-a2] line-through md:text-sm':
                        item.lineItem.discounts?.find((d) => d.description === 'VOLUME_CUSTOM') &&
                        item.lineItem.placedPrice.amount > 0 &&
                        item.lineItem.listPrice?.amount > item.lineItem.placedPrice.amount, // Apply line-through if there's a discount
                    }"
                  >
                    <VcItemPrice
                      :value="{ list: item.lineItem.listPrice, actual: item.lineItem.listPrice }"
                      :list-with-tax="item.lineItem.listPriceWithTax"
                      :actual-with-tax="item.lineItem.listPriceWithTax"
                    />
                    {{ $t("shared.cart.per_unit") }}
                  </span>
                </div> -->
              </div>
              <div v-if="item.lineItem.product?.mainProductId">
                <span> {{ $t("shared.cart.serial") }} #: {{ item.lineItem.sku }} </span>
              </div>

              <div
                v-if="item.lineItem.product?.isCalibrationServiceTL"
                class="cart__serial-number mt-2 text-sm leading-4 md:mt-1"
              >
                <span v-if="item.lineItem.itemNumberTL && item.lineItem.itemNumberTL.length > 0">
                  {{ $t("shared.cart.item") }} #: {{ item.lineItem.itemNumberTL }}
                </span>
                <span
                  v-if="
                    item.lineItem.serialNumberTL &&
                    item.lineItem.serialNumberTL.length > 0 &&
                    item.lineItem.itemNumberTL &&
                    item.lineItem.itemNumberTL.length > 0
                  "
                  class="mx-2 text-[--color-neutral-a3]"
                  >|</span
                >
                <span v-if="item.lineItem.serialNumberTL && item.lineItem.serialNumberTL.length > 0">
                  {{ $t("shared.cart.serial") }} #: {{ item.lineItem.serialNumberTL }}
                </span>
              </div>

              <div v-if="!isMobile" class="mt-2 flex gap-4">
                <!-- Remove button -->
                <button
                  :aria-label="$t('shared.cart.remove_from_cart')"
                  type="button"
                  class="flex items-center text-sm font-normal hover:underline"
                  @click="removeCartItem(item.lineItem.id)"
                >
                  <VcIcon name="trash-2" :size="20" class="mt-1" />
                  <span> {{ $t("shared.cart.remove_from_cart") }}</span>
                </button>
              </div>
            </div>
            <div
              class="cart__item-quantity mt-4 flex items-center justify-between md:mt-0 md:items-start md:justify-normal"
            >
              <div v-if="isMobile" class="flex gap-8">
                <!-- Remove button -->
                <button
                  :aria-label="$t('shared.cart.remove_from_cart')"
                  type="button"
                  class="flex items-center text-sm font-normal hover:underline"
                  @click="removeCartItem(item.lineItem.id)"
                >
                  <VcIcon name="trash-2" :size="20" class="mt-1" />
                  <span v-if="!isMobile"> {{ $t("shared.cart.remove_from_cart") }}</span>
                </button>
              </div>

              <VcQuantity
                :model-value="item.lineItem.quantity"
                :disabled="isVariant(item.lineItem)"
                :name="item.lineItem.id"
                :class="['w-[180px] md:w-[132px]', { 'cursor-not-allowed': isVariant(item.lineItem) }]"
                @update:model-value="changeItemQuantity(item.lineItem.id, $event)"
              >
                <template #prepend>
                  <VcButton
                    :aria-label="$t('common.buttons.decrease_quantity')"
                    :disabled="isVariant(item.lineItem)"
                    icon="minus-alt"
                    color="tertiary"
                    variant="outline"
                    :class="['qty-left w-[60px] md:w-[44px]', { 'cursor-not-allowed': isVariant(item.lineItem) }]"
                    size="md"
                    @click="decreaseQuantity(item.lineItem)"
                  />
                </template>
                <template #append>
                  <VcButton
                    :aria-label="$t('common.buttons.increase_quantity')"
                    :disabled="isVariant(item.lineItem)"
                    icon="plus-medium"
                    color="tertiary"
                    variant="outline"
                    :class="['qty-right w-[60px] md:w-[44px]', { 'cursor-not-allowed': isVariant(item.lineItem) }]"
                    size="md"
                    @click="increaseQuantity(item.lineItem)"
                  />
                </template>
              </VcQuantity>
            </div>
            <div
              v-if="item.lineItem.listPrice && !isMobile"
              class="cart__item-price flex flex-col items-end md:w-[145px]"
            >
              <!-- Promo price logic, displayed only if discountTotal exists and is greater than or equal to 0 -->
              <div
                v-if="
                  item.lineItem.placedPrice.amount > 0 &&
                  item.lineItem.listPrice?.amount > item.lineItem.placedPrice.amount
                "
              >
                <span class="price-promo text-base font-bold">
                  <VcItemPrice
                    :value="{ list: item.lineItem.listPriceAdaptedToQuantityTL, actual: item.lineItem.extendedPrice }"
                    :list-with-tax="item.lineItem.listPriceWithTaxAdaptedToQuantityTL"
                    :actual-with-tax="item.lineItem.extendedPriceWithTax"
                  />
                </span>
              </div>

              <!-- Original price logic -->
              <span
                v-if="!item.lineItem.discounts?.find((d) => d.description === 'VOLUME_CUSTOM')"
                :class="{
                  'price text-base font-bold': true, // Base styles
                  'price-line-trough font-normal text-[--color-neutral-a2] line-through':
                    item.lineItem.placedPrice.amount > 0 &&
                    item.lineItem.listPrice?.amount > item.lineItem.placedPrice.amount, // Apply line-through if there's a discount
                }"
              >
                <VcItemPrice
                  :value="{
                    list: item.lineItem.listPriceAdaptedToQuantityTL,
                    actual: item.lineItem.listPriceAdaptedToQuantityTL,
                  }"
                  :list-with-tax="item.lineItem.listPriceWithTaxAdaptedToQuantityTL"
                  :actual-with-tax="item.lineItem.listPriceWithTaxAdaptedToQuantityTL"
                />
              </span>
              <span class="mt-3 text-sm font-normal">
                {{ $t("shared.catalog.product_details.product_variations.availability") }}: {{ item.status }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-end gap-1.5 font-bold">
    {{ getSubtotalLabel() }} {{ itemsInCart }}:
    <VcPriceDisplay :formatted-value="formatPriceForCurrency(cart?.total, currentCurrency)" />
  </div>

  <div class="mt-6 md:mt-14">
    <VcButton
      v-bind="$attrs"
      type="button"
      class="flex w-full"
      color="primary"
      variant="solid"
      size="lg"
      @click="handleCheckoutClick"
    >
      {{ $t("common.labels.view_cart_checkout") }}
    </VcButton>

    <VcButton
      :to="{ name: 'Catalog', replace: true }"
      color="secondary"
      variant="outlineTL"
      size="lg"
      class="mt-4 flex w-full items-center"
      @click="handleButtonClick"
    >
      {{ $t("common.buttons.continue_shopping") }}
    </VcButton>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { onMounted, ref, defineEmits, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useCurrency } from "@/core/composables";
import { formatPriceForCurrency } from "@/shared/cart";
import { useFullCart } from "../composables/useCart";
import type { InputCustomerReferenceType, LineItemTypeExtension } from "@/core/api/graphql/types";
import type { ExtendedMenuLinkType } from "@/core/types";
import type { NavigationFailure } from "vue-router";
defineOptions({
  inheritAttrs: false,
});
const emit = defineEmits<{
  (e: "closeDialog"): void;
  (e: "change:itemQuantity", payload: { itemId: string; quantity: number }): void;
}>();
defineProps<IProps>();
const {
  removeItems,
  forceFetch,
  changeItemQuantity,
  cart,
  availabilityItems,
  itemsInCart,
  itemNumberAddedNotification,
  lastRemovedLineItem,
} = useFullCart();
const { t } = useI18n();
const { currentCurrency } = useCurrency();

interface IProps {
  link?: ExtendedMenuLinkType | undefined;
  count?: number | undefined;
  isActive: boolean;
  href: string;
  navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>;
}

const router = useRouter();

function handleButtonClick() {
  emit("closeDialog");
}

async function handleCheckoutClick() {
  await router.push({ name: "Cart" });
  handleButtonClick();
}

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("md");
const isDisabled = ref(false); // Update this based on your logic
const showItemAddedNotification = ref<boolean>(false);
const showItemRemovedNotification = ref<boolean>(false);

const sectionItems = ref<InputCustomerReferenceType[]>([]);

function getProductFamillyPageUrl(item: LineItemTypeExtension) {
  return item.product?.descriptions.find((x) => x.reviewType === "FamilyPageUrl")?.content;
}
function isVariant(lineItem: LineItemTypeExtension) {
  return !!lineItem.product?.mainProductId;
}

function decreaseQuantity(item: LineItemTypeExtension) {
  const inputElement = document.querySelector(`[name="${item.id}"]`) as HTMLInputElement;
  if (inputElement) {
    let quantity: number = parseInt(inputElement.value);
    if (!isNaN(quantity) && item.quantity > 1) {
      quantity -= 1;
      changeItemQuantity(item.id, quantity);
    }
  }
}

function increaseQuantity(item: LineItemTypeExtension) {
  const inputElement = document.querySelector(`[name="${item.id}"]`) as HTMLInputElement;
  if (inputElement) {
    let quantity: number = parseInt(inputElement.value);
    if (!isNaN(quantity)) {
      quantity += 1;
      changeItemQuantity(item.id, quantity);
    }
  }
}

const itemAddedNotification = computed<string>(() => {
  if (itemNumberAddedNotification.value?.product?.mainProductId) {
    return t("common.messages.minicart_serial_item_addded", {
      item: itemNumberAddedNotification.value.product?.masterVariation?.slug,
      serial: itemNumberAddedNotification.value.sku,
    });
  }

  return t("common.messages.minicart_item_addded", { item: itemNumberAddedNotification.value?.sku });
});

const itemRemovedNotification = computed<string>(() => {
  if (lastRemovedLineItem.value?.product?.mainProductId) {
    return t("common.messages.minicart_serial_item_removed", {
      item: lastRemovedLineItem.value.product?.masterVariation?.slug,
      serial: lastRemovedLineItem.value.sku,
    });
  }

  return t("common.messages.minicart_item_removed", { item: lastRemovedLineItem.value?.sku });
});

function getDescription(item: LineItemTypeExtension) {
  if (item.product?.mainProductId) {
    return item.product?.masterVariation?.descriptions?.find((d) => d.reviewType === "Title")?.content ?? "";
  } else {
    return item.product?.descriptions?.find((d) => d.reviewType === "Title")?.content ?? "";
  }
}

function getSubtotalLabel() {
  if (currentCurrency.value.code === "CNY") {
    return t("common.labels.subtotal_vat");
  } else {
    return t("common.labels.subtotal");
  }
}

watch([itemNumberAddedNotification, lastRemovedLineItem], ([newItemAdded, newItemRemoved]) => {
  if (newItemAdded) {
    showItemAddedNotification.value = true;
  }
  if (newItemRemoved) {
    showItemRemovedNotification.value = true;
  }
});

function closeItemAddedAlert() {
  showItemAddedNotification.value = false;
}

function closeItemRemovedAlert() {
  showItemRemovedNotification.value = false;
}

async function removeCartItem(itemId: string) {
  await removeItems([itemId]);
}

onMounted(async () => {
  await forceFetch();
});

defineExpose({
  sectionItems,
});

const DEFAULT_THUMB_SIZE = isMobile.value ? 80 : 100;
const DEFAULT_DETAIL_THUMB_SIZE = isMobile.value ? 80 : 100;
</script>

<style lang="scss">
.price-per-unit,
.price-line-trough {
  span {
    @apply font-normal #{!important};
  }
}
</style>
