<template>
  <div
    class="vc-alert"
    :class="[
      `vc-alert--${variant}`,
      `vc-alert--${variant}--${color}`,
      `vc-alert--size--${size}`,
      {
        'vc-alert--shadow': shadow,
      },
    ]"
  >
    <slot name="main-icon">
      <VcIcon v-if="icon" :name="iconName" class="vc-alert__icon" />
    </slot>

    <div class="vc-alert__content">
      <div v-if="title" class="vc-alert__title">{{ title }}</div>

      <slot />
    </div>

    <div v-if="closable">
      <button
        :aria-label="$t('common.buttons.close')"
        type="button"
        class="vc-alert__close-button"
        @click="$emit('close')"
      >
        <slot name="close-icon">
          <VcIcon name="close" />
        </slot>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { ValidAlertColor } from "@/core/types/alerts";

interface IEmits {
  (event: "close"): void;
}

interface IProps {
  color?: ValidAlertColor;
  icon?: boolean | string;
  variant?: "solid" | "solid-light" | "outline" | "outline-dark" | "outline-light";
  size?: "sm" | "md" | "lg";
  title?: string;
  shadow?: boolean;
  closable?: boolean;
}

defineEmits<IEmits>();

const props = withDefaults(defineProps<IProps>(), {
  variant: "solid",
  color: "info",
  size: "md",
});

const iconName = computed<string>(() => {
  const { icon } = props;

  if (icon && typeof icon === "string") {
    return icon;
  }

  switch (props.color) {
    case "danger":
      return "x-circle";

    case "warning":
      return "exclamation-circle";

    case "success":
      return "check-circle-alt";

    default:
      return "information-circle";
  }
});
</script>

<style lang="scss">
.vc-alert {
  $colors: success, warning, danger, default, info;
  $sizeSm: "";
  $sizeMd: "";
  $sizeLg: "";

  @apply flex items-stretch border rounded;

  &--shadow {
    @apply shadow-lg;
  }

  &--size {
    &--sm {
      $sizeSm: &;

      @apply px-[0.438rem] py-[0.313rem] min-h-[1.875rem] text-xs/[0.875rem];
    }

    &--md {
      $sizeMd: &;

      @apply p-[0.688rem] min-h-[2.75rem] text-sm/[1.125rem];
    }

    &--lg {
      $sizeLg: &;

      @apply px-[0.438rem] py-[0.313rem] min-h-[1.875rem] text-base;
    }
  }

  &--solid {
    @apply text-[--color-additional-50];

    @each $color in $colors {
      &--#{$color} {
        @apply bg-[--color-#{$color}-500] border-[--color-#{$color}-500];
      }
    }
  }

  &--solid-light {
    @apply text-[--color-neutral-900];

    @each $color in $colors {
      &--#{$color} {
        --vc-icon-color: var(--color-#{$color}-500);

        @apply bg-[--color-#{$color}-50] border-[--color-#{$color}-50];
      }
    }
  }

  &--outline {
    @apply bg-[--color-additional-50] text-[--color-neutral-900];

    @each $color in $colors {
      &--#{$color} {
        --vc-icon-color: var(--color-#{$color}-500);

        @apply border-[--color-#{$color}-500];
      }
    }
  }

  &--outline-light {
    @apply text-[--color-neutral-900] font-semibold;

    .vc-icon {
      @apply mt-1;
    }

    @each $color in $colors {
      &--#{$color} {
        --vc-icon-color: var(--color-#{$color}-500);

        @apply bg-[--color-#{$color}-500] border-[--color-#{$color}-500];
      }
    }

    &--default {
      --vc-icon-color: var(--color-warning-500);
      @apply bg-[--color-warning-1000] border-l-[--color-neutral-a4] border-l-[6px] border-0 leading-6 #{!important};
    }

    &--dark {
      --vc-icon-color: var(--color-neutral-a1);
      @apply bg-[--color-warning-1000] border-l-[--color-neutral-a1] border-l-[6px] border-0 leading-6 #{!important};
    }

    &--success {
      --vc-icon-color: var(--color-warning-500);
      @apply bg-[--color-warning-1000] border-l-[--color-warning-1001] border-l-[6px] border-0 leading-6 #{!important};
    }

    &--warning {
      --vc-icon-color: var(--color-alert-f3);
      @apply bg-[--color-alert-f4] border-l-[--color-alert-f3] border-l-[6px] border-0 leading-6 #{!important};
    }

    &--danger {
      --vc-icon-color: var(--color-primary-500);
      @apply bg-[--color-warning-1000] border-l-[--color-primary-500] border-l-[6px] border-0 leading-6 #{!important};
    }
  }

  &--outline-dark {
    @apply text-[--color-neutral-a1] bg-[--color-neutral-a6] border-l-[--color-neutral-a1] border-l-[6px] border-0 p-4 text-base;

    .vc-alert__content {
      @apply font-medium;
    }
  }

  &__icon {
    @apply shrink-0 me-2;

    #{$sizeSm} & {
      --vc-icon-size: 1.125rem;
    }

    #{$sizeMd} & {
      --vc-icon-size: 1.25rem;
    }

    #{$sizeLg} & {
      --vc-icon-size: 1.25rem;
    }
  }

  &__content {
    @apply grow flex flex-col justify-center;

    &:first-child {
      @apply ps-1;

      &:last-child {
        @apply px-1;
      }
    }
  }

  &__title {
    @apply mb-0.5 font-bold;
  }

  &__close-button {
    --vc-icon-color: currentColor;

    #{$sizeSm} & {
      --vc-icon-size: 1rem;

      @apply p-px;
    }

    #{$sizeMd} & {
      --vc-icon-size: 1.25rem;
    }

    #{$sizeLg} & {
      --vc-icon-size: 1.25rem;
    }
  }
}
</style>
