<template>
  <div class="main-wrapper print-page-ff flex min-h-screen flex-col">
    <VcHeader />

    <!-- Main Content -->
    <main id="maincontent" class="main flex grow flex-col items-center py-9 print:py-0">
      <slot />
    </main>

    <VcFooter />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from "vue";
import { VcFooter, VcHeader } from "@/shared/layout";

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === "Tab") {
    document.body.classList.add("tab-user");
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
});
</script>

<style scoped lang="scss">
#maincontent {
  &:has(.create-account-form, .log-in, .reset-password, .recover-account, .forgot-password, .welcome, .success) {
    @apply bg-[--color-neutral-a6];
  }

  &:has(.account-wrapper) {
    @apply py-0;
  }
  &:has(.cart) {
    @apply pt-4;
  }
  &:has(.welcome) {
    @apply justify-start;
  }
}
</style>
