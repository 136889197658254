import { graphqlClient } from "../../../client";
import queryDocument from "./getMetricReplacementItemIdsQuery.graphql";
import type { Query, QueryGetMetricReplacementItemIdsArgs, ReplacementIdPairType } from "@/core/api/graphql/types";

export async function getMetricReplacementItemIds(itemIds: Array<string>): Promise<ReplacementIdPairType[]> {
  const payload: QueryGetMetricReplacementItemIdsArgs = {
    itemIds,
  };
  const { data } = await graphqlClient.query<
    Required<Pick<Query, "getMetricReplacementItemIds">>,
    QueryGetMetricReplacementItemIdsArgs
  >({
    query: queryDocument,
    variables: { ...payload },
  });

  return data.getMetricReplacementItemIds;
}
