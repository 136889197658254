import { graphqlClient } from "../../../client";
import mutationDocument from "./moveWishlistItemMutation.graphql";
import type {
  InputMoveWishlistItemTypeExtension,
  Mutations,
  MutationsMoveWishlistItemArgs,
  WishlistType,
} from "@/core/api/graphql/types";

export async function moveWishlistItem(payload: InputMoveWishlistItemTypeExtension): Promise<WishlistType> {
  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "moveWishlistItem">>,
    MutationsMoveWishlistItemArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: payload,
    },
  });

  return data!.moveWishlistItem;
}
