import { graphqlClient } from "../../../client";
import mutationDocument from "./saveCartMutation.graphql";
import type { SaveCartType, MutationsSaveCartArgs, Mutations } from "../../../types";

export async function saveCart(cartInput: SaveCartType): Promise<string> {
  const { data } = await graphqlClient.mutate<Required<Pick<Mutations, "saveCart">>, MutationsSaveCartArgs>({
    mutation: mutationDocument,
    variables: { command: cartInput },
  });

  return data!.saveCart;
}
