<template>
  <router-link v-slot="{ isActive, href, navigate }" :to="link.route ?? ''" custom>
    <button
      v-bind="$attrs"
      :aria-label="$t('common.labels.minicart')"
      :class="[
        isActive
          ? 'text-[--header-bottom-link-active-color]'
          : 'text-[--header-bottom-link-color] hover:text-[--header-bottom-link-hover-color]',
      ]"
      class="flex h-12 items-center px-3 font-bold tracking-wide"
      type="button"
      role="menuitem"
      @click="showDialogMiniCart"
    >
      <span class="relative">
        <slot name="icon">
          <svg v-if="link.icon" height="20" width="20" class="mt-1">
            <use :href="link.icon" />
          </svg>
        </slot>

        <transition mode="out-in" name="scale">
          <VcBadge v-if="count" class="absolute -right-3 -top-2 transition-transform" variant="solid" rounded>
            {{ $n(count, "decimal", { notation: "compact" }) }}
          </VcBadge>
        </transition>
      </span>

      <span>
        <slot />
      </span>
    </button>

    <!-- Flyout-right -->
    <Dialog
      ref="dialogMiniCart"
      dialog-mode="medium"
      dialog-class="mobile-full"
      content-class="dialog-content"
      is-flyout
      @close-dialog="handleCloseDialog"
    >
      <template #title
        ><h3 class="m-0 text-lg font-semibold">{{ $t("pages.cart.title") }}</h3></template
      >

      <template #content>
        <MiniCart
          :href="href"
          :navigate="navigate"
          :is-active="isActive"
          :link="link"
          :count="count"
          @close-dialog="handleCloseDialog"
        />
      </template>
    </Dialog>
  </router-link>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { ExtendedMenuLinkType } from "@/core/types";
import MiniCart from "@/shared/cart/components/mini-cart.vue";
import Dialog from "@/shared/thorlabs/shared/components/dialog-modal.vue";

interface IProps {
  link: ExtendedMenuLinkType;
  count?: number;
}

defineOptions({
  inheritAttrs: false,
});

withDefaults(defineProps<IProps>(), {
  count: 0,
});

const dialogMiniCart = ref<InstanceType<typeof Dialog> | null>(null);
const showDialogMiniCart = () => {
  dialogMiniCart.value?.openDialog();
};

const handleCloseDialog = () => {
  dialogMiniCart.value?.closeDialog();
};
</script>

<style lang="scss">
.cart__item-quantity {
  .qty-left,
  .qty-right {
    @apply md:min-h-[42px] #{!important};
  }
}
</style>
