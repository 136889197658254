/* eslint-disable no-restricted-imports */
import axios from "axios";
import { Logger } from "@/core/utilities";
import { getFileExtension } from "../../shared";
import type { ICertificateInfo } from "./types";
import type { Compliance } from "@/core/api/graphql/types";
import type { AxiosRequestConfig } from "axios";

export const REACH_CERTIFICATE_TYPE = "REACH";
export const ROHS_CERTIFICATE_TYPE = "RoHS";
export const ROHS_NOT_APPLICABLE = "Not Applicable";
export const ASSET_GROUP_SDS = "SDS";
export const ASSET_GROUP_TSCA = "TSCA";
export const ASSET_GROUP_DSL = "DSL";
export const ASSET_GROUP_CATALOG_PRESENTATION = "Catalog Presentation";
export const ROHS_COMPLIANT = "Compliant";

export const rohsDoNotGenerate = ["Awaiting Information", "EU 2015/863", "Non-Compliant", "Not Applicable"];
export const reachDoNotGenerate = ["Awaiting Information", "Do Not Migrate", "Not Applicable"];

export const getAddressByUserCurrency = (currencyCode: string): string => {
  switch (currencyCode.toUpperCase()) {
    case "USD":
      return "43 Sparta Ave, Newton, NJ 07860, United States of America";
    case "EUR":
      return "Münchner Weg 1, 85232 Bergkirchen, Germany";
    case "GBP":
      return "204 Lancaster Way Business Park, Ely CB6 3NX, United Kingdom";
    case "SEK":
      return "Bergfotsgatan 7, 431 37 Mölndal, Sweden";
    case "CNY":
      return "Room A101, No. 100, Lane 2891, South Qilianshan Road, Putuo District, Shanghai 200331, China";
    case "JPY":
      return "3-6-3 Kitamachi, Nerima-ku, Tokyo 179-0081, Japan";
    case "BRL":
      return "Rua Rosalino Bellini, 175, Jardim Santa Paula, São Carlos, SP, 13564-050, Brazil";
    default:
      return "43 Sparta Ave, Newton, NJ 07860, United States of America";
  }
};

export const getPhoneByUserCurrency = (currencyCode: string): string => {
  switch (currencyCode.toUpperCase()) {
    case "USD":
      return "1-973-300-3000";
    case "EUR":
      return "+49 (0) 8131-5956-0";
    case "GBP":
      return "+44 (0) 1353-654440";
    case "SEK":
      return "+46-31-733-3000";
    case "CNY":
      return "+86 (0) 21-60561122";
    case "JPY":
      return "+81-3-6915-7701";
    case "BRL":
      return "+55 (21) 2018 6490";
    default:
      return "1-973-300-3000";
  }
};

export const getWebsiteByUserCurrency = (currencyCode: string): string => {
  switch (currencyCode.toUpperCase()) {
    case "USD":
      return "www.thorlabs.com";
    case "EUR":
      return "www.thorlabs.com";
    case "GBP":
      return "www.thorlabs.com";
    case "SEK":
      return "www.thorlabs.com";
    case "CNY":
      return "www.thorlabschina.cn";
    case "JPY":
      return "www.thorlabs.co.jp";
    case "BRL":
      return "www.thorlabs.com";
    default:
      return "www.thorlabs.com";
  }
};

export const getCertificationInfo = (compliances: Compliance[], doNotGenerateValues: string[]): ICertificateInfo => {
  const result: ICertificateInfo = {
    hasCertificate: false,
    shouldGenerateCertificate: false,
    values: [],
  };

  if (compliances.length > 0) {
    result.hasCertificate = true;
  } else {
    result.hasCertificate = false;
    return result;
  }

  //Check if any compliance value has a do not generate value
  const doNotGenerateValue = compliances.find((certificate) =>
    doNotGenerateValues.map((value) => value.toLowerCase()).includes(certificate.displayText?.toLowerCase() ?? ""),
  );

  if (doNotGenerateValue) {
    result.values.push(doNotGenerateValue.displayText);
    return result;
  }

  result.shouldGenerateCertificate = true;
  compliances.forEach((certificate) => {
    result.values.push(certificate.displayText);
  });

  return result;
};

export const openFile = async (fileId: string, fileName: string = "") => {
  const response = await fetch(`/api/thorlabs-products/display-file/${fileId}`);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const blob = await response.blob();
  let url = window.URL.createObjectURL(blob);

  if (fileName) {
    url += `#${fileName}`;
  }

  window.open(url, "_blank");
};

export const downloadFile = async (
  url: string,
  fileName: string,
  axiosConfig: AxiosRequestConfig | undefined = undefined,
  blobProperties: BlobPropertyBag | undefined = undefined,
) => {
  try {
    const response = await axios.get(url, axiosConfig);
    const blob = new Blob([response.data], blobProperties);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    Logger.error("Error downloading the file", error);
  }
};

export const normalizeDownloadFileName = (
  name: string,
  type: string,
  description: string,
  productCode: string,
): string => {
  let fileName = description?.trim() ? description : type;

  if (fileName.trim() === "") {
    return `${productCode}-File.${getFileExtension(name)}`;
  }

  fileName =
    `${productCode}-` +
    fileName.replace(" / ", "-").replace("(", "").replace(")", "").replace("'", "").replace(",", "").replace(" ", "-");

  return `${fileName}.${getFileExtension(name)}`;
};

export const getDocumentIconName = (group: string) => {
  if (!group) {
    return "";
  }

  const lowerCaseGroup = group.toLowerCase();

  if (lowerCaseGroup.includes("pdf")) {
    return "icon-cad";
  } else if (lowerCaseGroup.includes("solidworks")) {
    return "icon-solidworks";
  } else if (lowerCaseGroup.includes("edrawing")) {
    return "icon-box";
  } else if (lowerCaseGroup.includes("manual")) {
    return "icon-manual";
  } else {
    return "icon-document";
  }
};
