// import { useApolloClient } from "@vue/apollo-composable";
// import { useCartMutationVariables } from "@/core/api/graphql/cart/composables";
// import { useMutation } from "@/core/api/graphql/composables";
// import { ConvertCartDocument, OperationNames } from "@/core/api/graphql/types";
// import { filterActiveQueryNames } from "@/core/api/graphql/utils";
// import type { CartIdFragment } from "@/core/api/graphql/types";
// import type { MaybeRef } from "vue";

import { useCartMutationVariables } from "@/core/api/graphql/cart/composables";
import { useMutation } from "@/core/api/graphql/composables";
import { ConvertCartDocument } from "@/core/api/graphql/types";
import type { CartIdFragment } from "@/core/api/graphql/types";
import type { MaybeRef } from "vue";

export function useConvertCartMutation(cart?: MaybeRef<CartIdFragment | undefined>) {
  return useMutation(
    ConvertCartDocument,
    //useCartMutationVariables(cart, { refetchQueries: () => filterActiveQueryNames(client, [OperationNames.Query.GetFullCart]), }),
    useCartMutationVariables(cart, {}),
  );
}

/** @deprecated Use {@link useConvertCartMutation} instead. */
export async function convertCart(cart?: MaybeRef<CartIdFragment | undefined>) {
  const { mutate } = useConvertCartMutation(cart);
  const result = await mutate();
  return result!.data!.convertCart;
}
