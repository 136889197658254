<!-- eslint-disable vuejs-accessibility/interactive-supports-focus -->
<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div
    v-for="(item, index) in items"
    :key="index"
    class="main-nav group relative"
    :class="`group/menu-${index}`"
    role="menu"
    @mouseenter="openMenu(index)"
    @mouseleave="closeMenu()"
    @keydown.enter="openMenu(index)"
    @keydown.down.prevent="openMenu(index)"
    @keydown.esc="closeMenu()"
  >
    <button
      v-if="item.menuTemplate !== ''"
      type="button"
      class="flex h-[82px] select-none items-center whitespace-nowrap text-center text-base text-[color:var(--color-header-bottom-link)] focus:font-semibold focus:text-[--color-primary-a1] group-hover:text-[color:var(--color-header-bottom-link-hover)] group-focus:text-[color:var(--color-header-bottom-link-hover)]"
      :class="{ 'font-bold text-[--color-primary-a1]': activeItem === index }"
      role="menuitem"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span
        :data-title="item.label"
        class="after:top-100 block text-center before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
      >
        <span class="font-medium group-hover:font-semibold group-focus:font-semibold">{{ item.label }}</span>
      </span>
      <VcImage v-if="item.icon" class="ml-[2px] size-[18px]" :src="item.icon" alt="" role="presentation" />
    </button>
    <a
      v-if="item.menuTemplate === ''"
      class="flex h-[82px] select-none items-center whitespace-nowrap text-center text-base text-[color:var(--color-header-bottom-link)] focus:text-[--color-primary-a1] group-hover:text-[color:var(--color-header-bottom-link-hover)] group-focus:text-[color:var(--color-header-bottom-link-hover)]"
      :class="{
        'font-semibold text-[--color-primary-a1] focus:text-[color:var(--color-header-bottom-link-hover)] [&_svg]:text-[--color-primary-a1]':
          activeItem === index,
      }"
      :href="item.url"
      role="menuitem"
    >
      <span
        :data-title="item.label"
        class="after:top-100 block text-center before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)] after:absolute after:left-0 after:block after:h-8 after:w-full after:content-normal"
      >
        <span class="font-medium group-hover:font-semibold group-focus:font-semibold">{{ item.label }}</span>
      </span>
      <VcImage v-if="item.icon" class="ml-[2px] size-[18px]" :src="item.icon" alt="" role="presentation" />
    </a>
    <!-- Menu style 3 columns -->
    <div
      v-if="item.items.length > 0 && item.menuTemplate === 'three-columns'"
      class="absolute left-0 top-full z-10 border-b-[3px] border-b-[color:var(--color-primary-a1)] transition-all"
      :class="{
        visible: activeItem === index,
        'hidden ': activeItem !== index,
      }"
      role="menuitem"
      aria-haspopup="true"
    >
      <div class="flex border border-b-0 border-[color:var(--color-neutral-a4)] bg-white px-8 py-6 font-medium">
        <!-- Parents -->
        <div class="pr-6">
          <template v-for="(subItem, subIndex) in item.items" :key="subIndex">
            <template v-if="subItem.items.length > 0">
              <div class="relative" :class="`group/submenu-${subIndex}`" role="menu">
                <a
                  :ref="
                    (el) => {
                      if (el && 'focus' in el) parentRefs[`${subIndex}-${subIndex}`] = el as HTMLElement;
                    }
                  "
                  :href="subItem.url"
                  class="flex w-fit items-center whitespace-nowrap border-b-2 border-transparent pb-[2px] pt-1 font-normal"
                  :class="{ 'font-semibold [&_svg]:text-[inherit]': activeSubItem === subIndex }"
                  :style="{ borderColor: activeSubItem === subIndex ? subItem.color : '' }"
                  role="menuitem"
                  @mouseover="activeSubMenu(subIndex)"
                  @focusin="activeSubMenu(subIndex)"
                  @keydown.tab="activeSubMenu(subIndex)"
                  @keydown.right="activeSubMenuFocusElement(subIndex)"
                >
                  <span
                    v-if="subItem.label"
                    :data-title="subItem.label"
                    class="items-center before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
                    >{{ subItem.label }}</span
                  >
                  <div v-if="activeSubItem === subIndex" class="absolute -right-8 flex bg-white py-[11px]">
                    <VcIcon class="stroke-white stroke-2 text-[--color-neutral-a1]" name="chevron-right" :size="15" />
                  </div>
                </a>
              </div>
            </template>
          </template>
        </div>
        <div class="border-x border-x-[color:var(--color-neutral-a4)] px-6" role="menu">
          <!-- Children -->
          <div
            v-for="(subItem, subIndex) in item.items"
            :key="subIndex"
            :class="{
              'visible h-auto overflow-visible opacity-100 ': activeSubItem === subIndex,
              'invisible h-0 overflow-hidden opacity-0': activeSubItem !== subIndex,
            }"
            role="none"
          >
            <template v-if="subItem.items.length > 0">
              <!-- Second-level submenu for non-header items -->
              <div
                v-for="(subSubItem, subSubIndex) in subItem.items"
                :key="subSubIndex"
                class="transition-all"
                :class="{
                  'visible left-full opacity-100': activeSubItem === subIndex,
                  'invisible left-0 opacity-0': activeSubItem !== subIndex,
                }"
                role="none"
              >
                <a
                  :ref="
                    (el) => {
                      if (el && 'focus' in el) childLinkRefs[`${subIndex}-${subSubIndex}`] = el as HTMLElement;
                    }
                  "
                  :href="subSubItem.url"
                  :data-title="subSubItem.label"
                  class="flex flex-col whitespace-nowrap py-1 font-normal before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
                  :class="{
                    'hover:font-semibold focus:font-semibold': activeSubItem === subIndex,
                    'font-normal': activeSubItem !== subIndex,
                  }"
                  role="menuitem"
                  @keydown.left="activeSubMenuFocusParent(subIndex)"
                >
                  {{ subSubItem.label }}
                </a>
              </div>
            </template>
            <div v-if="subItem.image">
              <VcImage :src="subItem.image" class="mt-11 size-14 max-w-none" alt="" role="presentation" />
            </div>
          </div>
        </div>
        <!-- Orphans -->
        <div class="content-center self-center pl-6" role="none">
          <template v-for="(subItem, subIndex) in item.items" :key="subIndex">
            <div
              v-if="subIndex === item.items.length - 1"
              class="my-2 h-px w-4 bg-[--color-neutral-a4]"
              role="none"
            ></div>
            <template v-if="subItem.items.length == 0">
              <a
                :href="subItem.url"
                class="flex items-center gap-2 whitespace-nowrap py-1 font-normal hover:font-semibold"
              >
                <VcImage
                  v-if="subItem.icon"
                  class="size-[16px] max-w-none text-[--color-neutral-a1]"
                  :src="subItem.icon"
                  alt=""
                  role="presentation"
                />
                <span
                  v-if="subItem.label"
                  :data-title="subItem.label"
                  class="before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
                  >{{ subItem.label }}</span
                >
              </a>
            </template>
          </template>
        </div>
      </div>
      <div
        class="w-100 flex items-center justify-center border border-y-0 border-[color:var(--color-neutral-a4)] bg-[color:var(--color-neutral-a5)] px-8 py-2"
        role="none"
      >
        <a :href="item.url" class="group/all-menu flex items-center justify-center">
          <VcIcon class="ml-2 mt-[2px] text-[--color-neutral-a1]" name="grid" />
          <span
            :data-title="$t('shared.layout.header.menu.view_all_products')"
            class="text-center before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)] group-hover/all-menu:font-semibold"
            >{{ $t("shared.layout.header.menu.view_all_products") }}</span
          >
        </a>
      </div>
    </div>
    <!-- Menu style 2 columns -->
    <div
      v-if="item.menuTemplate === 'two-columns'"
      class="absolute left-0 top-full z-10 flex min-w-72 gap-8 whitespace-nowrap border border-b-[3px] border-[color:var(--color-neutral-a4)] border-b-[color:var(--color-primary-a1)] bg-white px-8 py-6 font-medium transition-all"
      :class="{ 'visible opacity-100': activeItem === index, 'invisible opacity-0': activeItem !== index }"
      role="none"
    >
      <!-- Left column -->
      <div v-if="item.items.length > 0" class="left-column" role="none">
        <template v-for="(subItem, subIndex) in item.items" :key="subIndex">
          <div v-if="!subItem.url" class="heading flex items-center py-1 pt-8 font-bold" role="none">
            <template v-if="subItem.label">
              {{ subItem.label }}
            </template>
          </div>
          <a v-else :href="subItem.url" class="flex items-center py-1 font-normal hover:font-semibold" role="menuitem">
            <span
              :data-title="subItem.label"
              class="before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
            >
              {{ subItem.label }}
            </span>
          </a>
          <template v-if="subItem.items.length > 0">
            <template v-for="(subSubItem, subSubIndex) in subItem.items" :key="subSubIndex">
              <a :href="subSubItem.url" class="flex items-center py-1 font-normal hover:font-semibold" role="menuitem">
                <span
                  :data-title="subSubItem.label"
                  class="before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
                >
                  {{ subSubItem.label }}
                </span>
              </a>
            </template>
          </template>
        </template>
      </div>
      <!-- Right column -->
      <div v-if="item.rightColumnItems.length > 0" class="right-column" role="none">
        <template v-for="(subItem, subIndex) in item.rightColumnItems" :key="subIndex">
          <div v-if="!subItem.url" class="heading flex items-center py-1 pt-8 font-bold" role="none">
            <template v-if="subItem.label">
              {{ subItem.label }}
            </template>
          </div>
          <a v-else :href="subItem.url" class="flex items-center py-1 font-normal hover:font-semibold" role="menuitem">
            <span
              :data-title="subItem.label"
              class="before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
            >
              {{ subItem.label }}
            </span>
          </a>
          <template v-if="subItem.items.length > 0">
            <template v-for="(subSubItem, subSubIndex) in subItem.items" :key="subSubIndex">
              <a :href="subSubItem.url" class="flex items-center py-1 font-normal hover:font-semibold" role="menuitem">
                <span
                  :data-title="subSubItem.label"
                  class="before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
                >
                  {{ subSubItem.label }}
                </span>
              </a>
            </template>
          </template>
        </template>
      </div>
    </div>
    <!-- Menu style 1 column -->
    <div
      v-if="item.items.length > 0 && item.menuTemplate === 'one-column'"
      class="absolute left-0 top-full z-10 flex min-w-40 flex-col border border-b-[3px] border-[color:var(--color-neutral-a4)] border-b-[color:var(--color-primary-a1)] bg-white px-8 py-6 font-medium transition-all"
      :class="{ 'visible opacity-100': activeItem === index, 'invisible opacity-0': activeItem !== index }"
      role="none"
    >
      <template v-for="(subItem, subIndex) in item.items" :key="subIndex">
        <div class="relative">
          <a
            :href="navigate(item.label, subItem.url)"
            class="flex whitespace-nowrap py-1 font-normal hover:font-semibold"
            role="menuitem"
          >
            <span
              :data-title="subItem.label"
              class="before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)]"
            >
              {{ subItem.label }}
            </span>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, reactive } from "vue";
import { useNavigations } from "@/core/composables";
import { mapGraphQLDataToMenuItems } from "@/shared/thorlabs/blocks";
import type { IDesktopMenuItem, IMenuItem } from "@/shared/thorlabs/blocks";
const props = defineProps<Props>();

interface Props {
  desktopMenuItems?: IDesktopMenuItem[];
}
const parentRefs = reactive<Record<string, HTMLElement | null>>({});
const childLinkRefs = reactive<Record<string, HTMLElement | null>>({});

const activeItem = ref<number | null>(null);
const activeSubItem = ref<number | 0>(0);
const items = ref<IMenuItem[]>();
const { mobilePreSelectedMenuItem, selectMenuItem, goMainMenu } = useNavigations();

onMounted(() => {
  goMainMenu();

  if (mobilePreSelectedMenuItem.value) {
    selectMenuItem(mobilePreSelectedMenuItem.value);
  }
});

watch(
  () => props.desktopMenuItems,
  (newValue) => {
    if (newValue) {
      const mappedMenuItems = mapGraphQLDataToMenuItems(newValue);
      items.value = mappedMenuItems;
    }
  },
  { immediate: true },
);

function activeSubMenu(subIndex: number) {
  activeSubItem.value = subIndex;
}
function openMenu(index: number) {
  activeItem.value = index;
}
function closeMenu() {
  activeItem.value = null;
}
function activeSubMenuFocusElement(subIndex: number) {
  const element = childLinkRefs[`${subIndex}-0`];
  if (element !== null) {
    element.focus();
  }
}
function activeSubMenuFocusParent(subIndex: number) {
  const key = `${subIndex}-${subIndex}`;
  if (parentRefs[key] !== null) {
    (parentRefs[key] as HTMLElement).focus();
  }
}
function navigate(label: string, url: string) {
  if (label === "Rapid Order") {
    const chuncks = url.split("/");
    const baseUrl = window.location.origin;
    return `${baseUrl}/cart?rapidOrder=${chuncks[chuncks.length - 2]}`;
  }

  return url;
}
</script>

<style scoped type="scss">
.right-column .heading:first-child,
.left-column .heading:first-child {
  @apply pt-1;
}

.main-nav:hover {
  button img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(100%) saturate(6243%) hue-rotate(10deg) brightness(92%)
      contrast(118%) !important;
  }
}

.main-nav {
  button:focus img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(100%) saturate(6243%) hue-rotate(10deg) brightness(92%)
      contrast(118%) !important;
  }
}
</style>
